import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "notificationsPool layout"
  }, [_c('h2', [_vm._v("Nachrichten")]), _vm._isAdmin ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-title noselect",
    on: {
      "click": function click($event) {
        _vm.showGroupConfiguration = !_vm.showGroupConfiguration;
      }
    }
  }, [_c('h2', {
    staticClass: "link"
  }, [_vm._v("Gruppenkonfiguration")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showGroupConfiguration,
      expression: "showGroupConfiguration"
    }],
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "margin bg-white"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.protectedGroups, function (n) {
    return _c('tr', [_c('td', {
      staticClass: "_small"
    }, [_c('span', [_vm._v(_vm._s(n.name))])]), _c('td', [_c('v-select', {
      attrs: {
        "multiple": "",
        "label": "name",
        "options": _vm._fbUsersSelectingArr,
        "reduce": function reduce(item) {
          return item.id;
        }
      },
      model: {
        value: n.usersList,
        callback: function callback($$v) {
          _vm.$set(n, "usersList", $$v);
        },
        expression: "n.usersList"
      }
    }, [_c('td')])], 1)]);
  }), _vm._l(_vm.userDefinedGroups, function (n, id) {
    return _c('tr', [_c('td', {
      staticClass: "_small tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: n.name,
        expression: "n.name"
      }],
      staticClass: "input",
      domProps: {
        "value": n.name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(n, "name", $event.target.value);
        }
      }
    })]), _c('td', [_c('v-select', {
      attrs: {
        "multiple": "",
        "label": "name",
        "options": _vm._fbUsersSelectingArr,
        "reduce": function reduce(item) {
          return item.id;
        }
      },
      model: {
        value: n.usersList,
        callback: function callback($$v) {
          _vm.$set(n, "usersList", $$v);
        },
        expression: "n.usersList"
      }
    })], 1), _c('td', {
      staticClass: "_ssmall"
    }, [_c('div', {
      staticClass: "tr"
    }, [_c('v-popover', {
      staticClass: "configbtn _hover",
      attrs: {
        "offset": "3",
        "placement": "right"
      }
    }, [_c('i', {
      staticClass: "icon-times color-red"
    }), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('ul', [_c('li', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "choose-block-li padding _small tac link color-red",
      on: {
        "click": function click($event) {
          return _vm.deleteGroup(id);
        }
      }
    }, [_vm._v("Sicher löschen?")])])])], 2)], 1)])]);
  })], 2)]), _c('div', {
    staticClass: "fl"
  }, [_c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.addNewGroup();
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  }), _c('span', [_vm._v("  neu Gruppe anlegen")])])]), _c('div', {
    staticClass: "fr"
  }, [_c('button', {
    staticClass: "btn _green",
    on: {
      "click": function click($event) {
        return _vm.updateNotificationGroup();
      }
    }
  }, [_vm._v("Speichern")])]), _c('div', {
    staticClass: "clear"
  })])])]) : _vm._e(), _c('tabs', {
    attrs: {
      "data": _vm.messageGroups,
      "selected": _vm.selectedTab,
      "no-add": ""
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selectedTab = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head",
      fn: function fn(_ref) {
        var name = _ref.data.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "tab",
      fn: function fn(_ref2) {
        var _ref2$data = _ref2.data,
          messages = _ref2$data.messages,
          recentlyClosed = _ref2$data.recentlyClosed;
        return [_c('div', {
          staticClass: "flex flex-direction-column gap-2"
        }, [_c('notifications-table', {
          staticClass: "open-messages",
          attrs: {
            "list": messages,
            "highlight-important-messages": true
          }
        }), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !!recentlyClosed.length,
            expression: "!!recentlyClosed.length"
          }],
          staticClass: "recently-closed"
        }, [_c('h2', [_vm._v("Kürzlich geschlossen")]), _c('notifications-table', {
          attrs: {
            "list": recentlyClosed,
            "highlight-important-messages": true
          }
        })], 1)], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', [_c('th', {
    staticClass: "_small tac"
  }, [_vm._v("Name")]), _c('th', [_vm._v("Benutzer")]), _c('th', {
    staticClass: "_ssmall"
  }, [_c('div', {
    staticClass: "tr"
  }, [_vm._v("x")])])]);
}];
export { render, staticRenderFns };